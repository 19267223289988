<template>
  <div class="style">
    style
    <el-button size="normal" plain>朴素按钮</el-button>
    <el-button type="primary" size="normal" plain>主要按钮</el-button>
    <el-button type="primary" size="normal">主要按钮</el-button>
    <el-button type="primary" size="normal" :disabled="true">主要按钮</el-button>
    <el-button type="text">文字按钮</el-button>
    <div style="background:#000;">
      <el-button class="main-white" type="text">文字按钮</el-button>
    </div>
    <icon-font
      class="mr-2"
      name="#mdd-fubianLOGO"
      :width="36"
      :height="36"
      color="#2B7FE2"
    ></icon-font>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'style',
  components: {
  }
}
</script>

<style lang="less" scoped>
</style>
